import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import * as shortid from "shortid"
import Helmet from "react-helmet"
import PropTypes from "prop-types"
import { LocaleContext } from "../contexts/LocaleContext"

const SEO = ({ schema, ogp, meta, locale, breadcrumbs, location }) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  const data = useStaticQuery(query)

  const ogpMeta = [
    <meta
      key={shortid.generate()}
      property="og:locale"
      content={i18n.ogLang}
    />,
    <meta
      key={shortid.generate()}
      property="fb:app_id"
      content="388574314530384"
    />,
    <meta
      key={shortid.generate()}
      property="og:title"
      content={`${meta.title} | Emil Mureșan`}
    />,
    <meta
      key={shortid.generate()}
      property="og:description"
      content={meta.description}
    />,
    <meta
      key={shortid.generate()}
      property="og:site_name"
      content="Galeria de artă Emil Mureșan"
    />,
    <meta key={shortid.generate()} property="og:type" content="website" />,
    <meta
      key={shortid.generate()}
      property="og:url"
      content={`${process.env.GATSBY_BASE_URL}/`}
    />,
  ]

  ogp
    .filter(tag => tag.content && tag.property)
    .forEach(tag => {
      ogpMeta.push(
        <meta
          key={shortid.generate()}
          property={tag.property}
          content={tag.content}
        />
      )
    })

  const [lastBreadcrumb] = breadcrumbs.slice(-1)
  const { path } = lastBreadcrumb
  const canonical = `${process.env.GATSBY_BASE_URL}${path}`

  const parsedSchema = JSON.parse(schema)

  parsedSchema["@graph"].push({
    "@type": "BreadcrumbList",
    "@context": "https://schema.org",
    numberOfItems: breadcrumbs.length,
    itemListElement: breadcrumbs.map((breadcrumb, index) => ({
      "@type": "ListItem",
      position: index + 1,
      item: `${process.env.GATSBY_BASE_URL}${breadcrumb.path}`,
      name: breadcrumb.name,
    })),
  })

  parsedSchema["@graph"].push({
    "@type": "WebPage",
    "@id": `${canonical}#webpage`,
    url: canonical,
    inLanguage: i18n.hrefLang.toLowerCase(),
    name: meta.title,
    description: meta.description,
    datePublished: "2020-11-09T18:33:58+0000",
    dateModified: "2020-11-09T20:17:19+0000",
  })

  parsedSchema["@graph"].push({
    "@type": "ArtGallery",
    name: "Galeria de artă Emil Muresan",
    image: `${process.env.GATSBY_BASE_URL}${data.logoSimple.publicURL}`,
    "@id": `${canonical}#ArtGallery`,
    url: `${process.env.GATSBY_BASE_URL}/`,
    telephone: "+40751101579 ",
    priceRange: "$",
    address: {
      "@type": "PostalAddress",
      streetAddress: i18n.organizationStreetAddress,
      addressLocality: i18n.organizationAddressLocality,
      postalCode: "551020",
      addressCountry: "RO",
    },
    geo: {
      "@type": "GeoCoordinates",
      latitude: 46.16877,
      longitude: 24.3478633,
    },
    openingHoursSpecification: {
      "@type": "OpeningHoursSpecification",
      dayOfWeek: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      opens: "10:00",
      closes: "16:00",
    },
  })

  return (
    <>
      <Helmet title={`${meta.title} | Emil Mureșan`}>
        <html lang={i18n.siteLanguage} />
        <meta name="description" content={meta.description} />
        <meta name="keywords" content={meta.keywords} />

        <script type="application/ld+json">
          {JSON.stringify(parsedSchema)}
        </script>

        {ogpMeta}
      </Helmet>
    </>
  )
}

export default SEO

const query = graphql`
  query SeoQuery {
    logoSimple: file(relativePath: { eq: "emilmuresan.png" }) {
      publicURL
    }
  }
`

SEO.defaultProps = {
  schema: {},
}

SEO.propTypes = {
  locale: PropTypes.string.isRequired,
  schema: PropTypes.string,
  ogp: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired,
  breadcrumbs: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
}
